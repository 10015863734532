import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MainLayoutModule } from './pages/main-layout/main-layout.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { LoaderModule } from './core/shared/components/loader/loader.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TokenInterceptorServiceProviders } from './core/interceptor/auth-interceptor.service';
import { StreamChatModule, StreamAutocompleteTextareaModule } from 'stream-chat-angular';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MainLayoutModule,
    HttpClientModule,
    LoaderModule,
    MatDialogModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatPaginatorModule,
    NgxMaterialTimepickerModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: "toast-top-right",
    }),
    StreamChatModule,
    TranslateModule.forRoot(),
    NgxExtendedPdfViewerModule,
    MatAutocompleteModule
  ],
  providers: [TokenInterceptorServiceProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
